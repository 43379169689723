import './TermsOfService.css';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const TermsOfService = ({ standalone }) => {
    return (
        <div class="tos">
            <header>
                <h1>Terms of Service and Copyright Official 2024</h1>
                <h2>SledTRX LLC.</h2>
            </header>

            <section>
                <h2>Terms of Service</h2>
                <p><strong>Effective Date:</strong> 10/14/24</p>
                <p>Welcome to SledTRX! By using our platforms, including the web-app and mobile applications available on Google Play and the Apple App Store, you agree to comply with these Terms of Service (“Terms”). These Terms govern your access to and use of SledTRX's services and data, so please read them carefully.</p>

                <h3>1. Acceptance of Terms</h3>
                <p>By accessing or using any part of the SledTRX platform, you agree to these Terms. If you do not agree with these Terms, you must discontinue use of the platform immediately.</p>

                <h3>2. User Responsibilities</h3>
                <ul>
                    <li><strong>Account Registration:</strong>
                        <ul>
                            <li>Users must provide accurate, current, and complete information during registration and are responsible for maintaining the confidentiality of their login credentials.</li>
                        </ul>
                    </li>
                    <li><strong>Platform Use:</strong>
                        <ul>
                            <li>You agree to use SledTRX for lawful purposes only and in compliance with all local, state, and federal laws.</li>
                            <li>Unauthorized use of SledTRX’s map data, software, or platform functionalities, such as copying or distributing materials without permission, is strictly prohibited.</li>
                        </ul>
                    </li>
                    <li><strong>User Conduct:</strong>
                        <ul>
                            <li>Users must not engage in behavior that could harm the platform or its users, including hacking, transmitting malware, or attempting to access restricted areas of the site or app.</li>
                        </ul>
                    </li>
                </ul>

                <h3>3. Disclaimer of Warranties</h3>
                <ul>
                    <li><strong>Mapping Accuracy:</strong>
                        <ul>
                            <li>While SledTRX strives to provide accurate mapping and trail data, we cannot guarantee the completeness or accuracy of all information. Users are encouraged to cross-reference trails with local clubs or official sources for the most current information.</li>
                        </ul>
                    </li>
                    <li><strong>Service Availability:</strong>
                        <ul>
                            <li>SledTRX is provided on an "as-is" and "as-available" basis. We do not guarantee uninterrupted or error-free access to the platform and services.</li>
                        </ul>
                    </li>
                </ul>

                <h3>4. Limitation of Liability</h3>
                <ul>
                    <li><strong>Personal Responsibility:</strong>
                        <ul>
                            <li>You assume full responsibility for your use of SledTRX, including navigation and safety during recreational activities. SledTRX is not liable for any injury, loss, or damage arising from your use of the platform or reliance on the data provided.</li>
                        </ul>
                    </li>
                    <li><strong>Limitations:</strong>
                        <ul>
                            <li>To the maximum extent permitted by law, SledTRX will not be liable for any indirect, incidental, or consequential damages, including lost profits, arising out of or in connection with your use of the platform.</li>
                        </ul>
                    </li>
                </ul>

                <h3>5. Intellectual Property</h3>
                <ul>
                    <li><strong>Copyright:</strong>
                        <ul>
                            <li>All content on SledTRX, including data, software, images, logos, and other intellectual property, is protected by copyright and is owned or licensed by SledTRX. Any unauthorized reproduction or redistribution is prohibited.</li>
                        </ul>
                    </li>
                    <li><strong>Trademarks:</strong>
                        <ul>
                            <li>SledTRX trademarks, including logos, names, and any distinctive marks, are legally protected and may not be used without our written consent.</li>
                        </ul>
                    </li>
                </ul>

                <h3>6. Governing Law & Dispute Resolution</h3>
                <ul>
                    <li><strong>Jurisdiction:</strong>
                        <ul>
                            <li>These Terms shall be governed by and construed in accordance with the laws of the United States. Any disputes will be handled through arbitration in the State of Maine.</li>
                        </ul>
                    </li>
                    <li><strong>Dispute Resolution:</strong>
                        <ul>
                            <li>Any legal disputes arising under these Terms shall be resolved through binding arbitration, with each party responsible for their own legal costs.</li>
                        </ul>
                    </li>
                </ul>

                <h3>7. Changes to Terms</h3>
                <ul>
                    <li><strong>Modifications:</strong>
                        <ul>
                            <li>SledTRX reserves the right to modify these Terms at any time. Users will be notified of any significant changes via email or platform notifications. Continued use of the platform after changes take effect constitutes acceptance of the new Terms.</li>
                        </ul>
                    </li>
                </ul>

                <h3>8. Contact Information</h3>
                <p>For questions or concerns regarding these Terms of Service, please contact us at:</p>
                <p>Email: <a href="mailto:customerservice@sledtrx.com">customerservice@sledtrx.com</a></p>
            </section>

            <footer>
                <p>&copy; 2024 SledTRX LLC. All rights reserved.</p>
            </footer>
            <p>
                Last Updated: 12/8/2024
            </p>
        </div>
    );
};

export default TermsOfService;