import FirebaseRealtimeDatabaseProvider from "../../../providers/FirebaseRealtimeDatabaseProvider";
import { config } from "../../../firebase/firebase";

/**
 * Resets the TOS agreement flag for all users.
 * 
 * Done to trigger a resign upon update of the TOS.
 * 
 * @returns {Promise<void>}
 */
const resetAllUsersTOSAgreements = async () => {
    // make sure the user is sure before continuing
    if (!window.confirm('This will force all users to resign TOS. Are you sure you want to continue?')) {
        return;
    }

    const dataProvider = FirebaseRealtimeDatabaseProvider(config.databaseURL)

    let users = await dataProvider.getList('users');

    if (!users?.data) {
        return
    }

    users = users.data;

    users.forEach((user) => {
        dataProvider.update('users', {
            id: user.id,
            data: {
                ...user,
                agreed_to_terms_at: null,
            },
        });     
    });
}

export { resetAllUsersTOSAgreements };