import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../firebase/auth'; 
import { useEffect } from 'react';
import FirebaseRealtimeDatabaseProvider from '../providers/FirebaseRealtimeDatabaseProvider';

const CheckTosOnAuth = ({ children }) => {
    const { authUser, refreshAuthUser } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const dataProvider = FirebaseRealtimeDatabaseProvider(); 

    const checkCurrentUserStats = async () => {
        if (!authUser) {
            return;
        }

        if (!authUser.agreed_to_terms_at && location.pathname !== '/') {
            navigate('/');
        }

        const now = new Date();
        if (authUser.last_refreshed_at) {
            const lastRefreshed = new Date(authUser.last_refreshed_at);
            const diff = now - lastRefreshed;
            const diffInMinutes = Math.floor(diff / 60000);

            if (diffInMinutes < 5) {
                return;
            }
        }

        refreshAuthUser();
    }

    useEffect(() => {
        if (location?.pathname === '/' || location?.pathname === '/Terms') {
            return;
        }
        checkCurrentUserStats();
    }, [authUser, location.pathname]);

    return children;
};

export default CheckTosOnAuth;