import { useState } from 'react';
import { Outlet, useNavigate, useOutlet } from 'react-router-dom';
import { useRedirect } from 'react-admin';
import { useAuth } from '../../firebase/auth';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import FirebaseRealtimeDatabaseProvider from '../../providers/FirebaseRealtimeDatabaseProvider';
import { config } from '../../firebase/firebase';

const Delete = (props) => {
    const { authUser, signOut } = useAuth();
    const navigate = useNavigate();
    const redirect = useRedirect();

    const [confirming, setConfirming] = useState(false);

    const handleSignOut = () => {
        signOut();
        navigate('/');
    };

    const handleDelete = () => {
        console.log(authUser);
        if (!authUser || !authUser.id) {
            return;
        }

        const dataProvider = FirebaseRealtimeDatabaseProvider(config.databaseURL);

        dataProvider.delete('users', {id: authUser.id}).then(() => {
            signOut();
            navigate('/');
        });
    };

    return (
        <Stack spacing={4}>
            <Typography variant="h3">Delete Account</Typography>
            <Typography variant="body1">
                This option will permantenly delete your account and all related information. 
                <strong>This will not cancel any subscriptions! If you have a subscription to use the iOS or Android apps please cancel the subscriptions from your phone's settings.</strong>
            </Typography>
            <Typography variant="body1">
                For more information on how to cancel subscriptions, please visit the following links:
                <br />
                <a href="https://support.apple.com/en-us/118428" target="_blank" rel="noopener noreferrer">Apple Subscriptions</a>
                <br />
                <a href="https://support.google.com/googleplay/answer/7018481?hl=en&co=GENIE.Platform%3DAndroid" target="_blank" rel="noopener noreferrer">Android Subscriptions</a>
            </Typography>
            {confirming ?
                (
                    <Stack spacing={2}>
                        <Typography variant="body1">This action is irreversible and will delete all of your account data.</Typography>
                        {confirming ?
                            <Typography 
                                variant="h6"
                                sx={{color: 'error.main'}}
                            >
                                Are you sure you want to delete your account? By deleting you acknowledge that any active iOS or Android app subscriptions will not be cancelled.
                            </Typography>
                        : null}
                        <Button onClick={handleDelete} variant="contained" color="error">Yes, Delete My Account</Button>
                        <Button onClick={() => setConfirming(false)} variant="outlined">Cancel</Button>
                    </Stack>
                )
            : <Button onClick={() => setConfirming(true)} variant="contained" color="error">Delete Account</Button>
            }
        </Stack>
    );
};

export default Delete;