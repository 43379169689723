import { useEffect } from 'react';
import { Outlet, useNavigate, useOutlet } from 'react-router-dom';
import { useRedirect } from 'react-admin';
import { useAuth } from '../../firebase/auth';
import NavLayout from '../../components/NavLayout/NavLayout';
import NavLayoutItem from '../../components/NavLayoutItem/NavLayoutItem';
import NavLayoutSection from '../../components/NavLayoutSection/NavLayoutSection';

const Account = (props) => {
    const { authUser, signOut } = useAuth();
    const navigate = useNavigate();
    const outlet = useOutlet();
    const redirect = useRedirect();

    const handleSignOut = () => {
        signOut();
        navigate('/');
    };

    useEffect(() => {
        //Redirect to the profile if the user visited /Account directly
        if (!outlet) {
            redirect('/Account/Profile');
        }
    }, []);

    const AccountNav = (
        <NavLayoutSection>
            <NavLayoutItem key='Account' to='/Account/Profile'>Your Profile</NavLayoutItem>
            {authUser?.club_ids ?
                <NavLayoutItem key='Trails' to='/Account/Trails'>Your Club's Trails</NavLayoutItem> :
                null
            }
        </NavLayoutSection>
    );

    const AccountSecondaryNav = (
        <NavLayoutSection>
            {authUser?.type === 'admin' ?
                <NavLayoutItem key='Admin' to='/admin'>Administration</NavLayoutItem> :
                null
            }
            <NavLayoutItem key='Delete' to='/Account/Delete'>Delete Account</NavLayoutItem>
            <NavLayoutItem key='Logout' onClick={handleSignOut}>Logout</NavLayoutItem>
        </NavLayoutSection>
    );

    return (
        <NavLayout navigation={AccountNav} secondary={AccountSecondaryNav}>
            <Outlet />
        </NavLayout>
    );
};

export default Account;